<template>
    <div class="updatePas">
        <van-nav-bar title="修改密码" safe-area-inset-top  placeholder fixed  left-text="返回" left-arrow @click-left="$router.push('/login')" /> 
        <!-- 手机号验证中 -->
        <div class="FormBoX" v-if="status==1">
            <van-form @submit="onSubmit" :show-error="false" ref="vanForm" autocomplete="off">
                <van-field
                    v-model="IphoneData.phone"
                    name="phone"
                    label="手机号"
                    maxlength="11"
                    placeholder="请输入手机号"
                    :rules="[{ required: true, message: '请输入手机号' },
                             { validator:iphoneReg, message: '请输入正确手机号' }

                    ]"/>
                <van-field
                    v-model="IphoneData.vercode"
                    center
                    clearable
                    name="sms"
                    label="验证码"
                    placeholder="请输入短信验证码"
                    :rules="[{ required: true, message: '请输入短信验证码' }]">
                    
                    <template slot="button">
                        <van-button class="smsBtn" size="small" :disabled="smsdisabled" type="primary" native-type="button" @click="smsClick">{{smsTxt}}</van-button>
                    </template>
                </van-field>
                <div class="btnBox">
                    <van-button round block type="info" native-type="submit" size="small" >手机号验证</van-button>
                    <div class="TipsBox">
                        <router-link class="heSecret" tag='span' to='/login'>直接登陆</router-link>
                    </div>
                </div>
            </van-form>
        </div>
        <!-- 修改密码 -->
          <div class="FormBoX" v-if="status == 2">
            <van-form @submit="UpdataPassWord" :show-error="false" autocomplete="off" ref="UpdataPassWordForm">
                <van-field
                    v-model="NewPassWord"
                    name="tel"
                    label="新密码"
                  
                    :right-icon="passwoed1?'eye-o':'closed-eye'" @click-right-icon='passwoed1 = !passwoed1' label-width='60px' :type="passwoed1?'password':'text'"
                    placeholder="请输入新密码"
                    :rules="[{ required: true, message: '请输入新密码' },
                    { validator:passwordLength, message: '密码长度不能小于六位',trigger : 'onBlur'}
                    
                    ]"/>
                <van-field
                    v-model="AginNewPassWord"
                    name="tel"
                    label="确认密码"
                  
                    :right-icon="passwoed2?'eye-o':'closed-eye'" @click-right-icon='passwoed2 = !passwoed2' label-width='60px' :type="passwoed2?'password':'text'"
                    placeholder="请确认密码"
                    :rules="[
                    { required: true, message: '请确认密码' },
                    { validator:passwordAgin, message: '两次输入密码不一致' }
                    
                    ]"/>

                <div class="btnBox">
                    <van-button round block type="info" native-type="submit" size="small" >修改密码</van-button>
                    <div class="TipsBox">
                        <router-link class="heSecret" tag='span' to='/login'>直接登陆</router-link>
                    </div>
                </div>
            </van-form>
        </div>
    </div>
</template>
<script>
import { NavBar , Form ,Field , Button ,Toast   } from 'vant';
import {Api_System_SeedCode} from "@/api/userInfo.js"
import {Api_Login_YyCheckCode,Api_Login_YyUpdatePassWord} from "@/api/login.js"
import {Encryption} from '@/utils/validate'
export default {
    components:{
        [NavBar.name]: NavBar,
        [Form.name]: Form,
        [Field.name]: Field,
        [Button.name]: Button,
        [Toast.name]: Toast,
    },
    data() {
        return {
            IphoneData:{
                phone:"",
                vercode:''
            },
            passwoed1:true,
            passwoed2:true,
            smsdisabled:false,
            smsTxt:'发送验证码',
            status:1,
            vercode_token:'',
            NewPassWord:"",
            AginNewPassWord:''
        }
    },
    created(){
         // 页面刷新  验证码倒计时继续
        let fistYzmSecond = localStorage.getItem('YzmSecond');
        if(fistYzmSecond) {
           
            let currentSecond = Date.parse(new Date())/1000
            var second = currentSecond - fistYzmSecond;
            if(second>=90){
                return
            }else {
                this.smsdisabled = true
                second = 90-second;
                this.smsTxt = '重新获取('+second+'s)'
                var Time = setInterval(()=>{
                second--
                this.smsTxt = '重新获取('+second+'s)'
                    if(second <= 0){
                        this.smsTxt = '获取验证码'
                        this.smsdisabled = false;
                        localStorage.setItem('YzmSecond','')
                        clearInterval(Time)
                    }
                },1000)
            }
        }
    },
    methods:{
        // 雁阵手机号
        iphoneReg(){
            var myreg=/^[1][2,3,4,5,6,7,8,9][0-9]{9}$/;
            if (!myreg.test(this.IphoneData.phone)) {
                return false;
            } else {
                return true;
            }
        },
        // 获取验证码
        smsClick(){

            // if (sessionStorage.getItem('errIphone') == this.IphoneData.phone) return
            this.$refs.vanForm.validate("phone").then(()=>{
                this.smsdisabled = true;
                Api_System_SeedCode({
                    phone:this.IphoneData.phone
                }).then(res=>{
                    this.$toast("短信发送成功")
                   
                    localStorage.setItem('vercode_token',res.vercode_token)
                    localStorage.setItem('YzmSecond',Date.parse(new Date())/1000)

                    var second = 90;
                    var Time = setInterval(()=>{
                        second--
                        this.smsTxt = '重新获取('+second+'s)'
                        if(second <= 0){
                            this.smsTxt = '获取验证码'
                            this.smsdisabled = false;
                            localStorage.setItem('YzmSecond','')
                            clearInterval(Time)
                        }
                    },1000)
                }).catch(rs=>{
                    this.$toast(rs.msg)
                    this.smsdisabled = false;
                    // sessionStorage.setItem("errIphone",this.IphoneData.phone)

                })
            }).catch(res=>{})
        },
        // 验证手机号
        onSubmit(){
            Api_Login_YyCheckCode(Object.assign({},this.IphoneData,{
                vercode_token:localStorage.getItem('vercode_token')
            })).then(res=>{
                sessionStorage.setItem('UserID',res.UserID)
                this.status = 2;
            })
            
        },
        // 密码长度问题
        passwordLength(){
            if(this.NewPassWord.length < 6 ) {
                return false
            }else {
                return true
            }
        },
        // 判断密码是否一致
        passwordAgin(){
           console.log(1)
            if(this.NewPassWord === this.AginNewPassWord) {
                return true
            }else {
                return false
            }
        },
        // 修改手机号
        UpdataPassWord(){
            

            Api_Login_YyUpdatePassWord({
                NewPassWord:Encryption(this.NewPassWord),
                UserID:sessionStorage.getItem("UserID")
            }).then(res=>{
                console.log(res)
                this.$toast('密码修改成功')

                this.$router.push('/login')
                localStorage.clear()
                sessionStorage.clear()
            }).catch(res=>{
                this.$toast(res.msg)
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.updatePas {
    height: 100vh;
    background: #fff;
    background-image: url("./../../assets/Image/LoginBaner.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 0 bottom;
}
.FormBoX {
    padding-top: 8px;
    background: #f2f2f2;
 
    // padding-bottom: 5px;
    .smsBtn {
        height: 24px;
        line-height: 24px;
    }
    /deep/ .van-field__button {
        height: 24px;
        line-height: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}
.btnBox {
    padding: 12px 16px;
    background: #fff;
    padding-top: 20px;

    .van-button--small {
        background: linear-gradient(to right, #87dfff, #77beef);
        border-color: transparent;
        height: 38px;
        line-height: 38px;
        font-size: 14px;
    }
    .TipsBox {
        text-align: right;
        padding-right: 8px;
        padding-top: 8px;
        .heSecret {
            font-size: 14px;
            color:#1989fa ;
            letter-spacing: 1PX;
            text-decoration: underline;
        }
    }
}
</style>