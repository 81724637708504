var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"updatePas"},[_c('van-nav-bar',{attrs:{"title":"修改密码","safe-area-inset-top":"","placeholder":"","fixed":"","left-text":"返回","left-arrow":""},on:{"click-left":function($event){return _vm.$router.push('/login')}}}),(_vm.status==1)?_c('div',{staticClass:"FormBoX"},[_c('van-form',{ref:"vanForm",attrs:{"show-error":false,"autocomplete":"off"},on:{"submit":_vm.onSubmit}},[_c('van-field',{attrs:{"name":"phone","label":"手机号","maxlength":"11","placeholder":"请输入手机号","rules":[{ required: true, message: '请输入手机号' },
                         { validator:_vm.iphoneReg, message: '请输入正确手机号' }

                ]},model:{value:(_vm.IphoneData.phone),callback:function ($$v) {_vm.$set(_vm.IphoneData, "phone", $$v)},expression:"IphoneData.phone"}}),_c('van-field',{attrs:{"center":"","clearable":"","name":"sms","label":"验证码","placeholder":"请输入短信验证码","rules":[{ required: true, message: '请输入短信验证码' }]},model:{value:(_vm.IphoneData.vercode),callback:function ($$v) {_vm.$set(_vm.IphoneData, "vercode", $$v)},expression:"IphoneData.vercode"}},[_c('template',{slot:"button"},[_c('van-button',{staticClass:"smsBtn",attrs:{"size":"small","disabled":_vm.smsdisabled,"type":"primary","native-type":"button"},on:{"click":_vm.smsClick}},[_vm._v(_vm._s(_vm.smsTxt))])],1)],2),_c('div',{staticClass:"btnBox"},[_c('van-button',{attrs:{"round":"","block":"","type":"info","native-type":"submit","size":"small"}},[_vm._v("手机号验证")]),_c('div',{staticClass:"TipsBox"},[_c('router-link',{staticClass:"heSecret",attrs:{"tag":"span","to":"/login"}},[_vm._v("直接登陆")])],1)],1)],1)],1):_vm._e(),(_vm.status == 2)?_c('div',{staticClass:"FormBoX"},[_c('van-form',{ref:"UpdataPassWordForm",attrs:{"show-error":false,"autocomplete":"off"},on:{"submit":_vm.UpdataPassWord}},[_c('van-field',{attrs:{"name":"tel","label":"新密码","right-icon":_vm.passwoed1?'eye-o':'closed-eye',"label-width":"60px","type":_vm.passwoed1?'password':'text',"placeholder":"请输入新密码","rules":[{ required: true, message: '请输入新密码' },
                { validator:_vm.passwordLength, message: '密码长度不能小于六位',trigger : 'onBlur'}
                
                ]},on:{"click-right-icon":function($event){_vm.passwoed1 = !_vm.passwoed1}},model:{value:(_vm.NewPassWord),callback:function ($$v) {_vm.NewPassWord=$$v},expression:"NewPassWord"}}),_c('van-field',{attrs:{"name":"tel","label":"确认密码","right-icon":_vm.passwoed2?'eye-o':'closed-eye',"label-width":"60px","type":_vm.passwoed2?'password':'text',"placeholder":"请确认密码","rules":[
                { required: true, message: '请确认密码' },
                { validator:_vm.passwordAgin, message: '两次输入密码不一致' }
                
                ]},on:{"click-right-icon":function($event){_vm.passwoed2 = !_vm.passwoed2}},model:{value:(_vm.AginNewPassWord),callback:function ($$v) {_vm.AginNewPassWord=$$v},expression:"AginNewPassWord"}}),_c('div',{staticClass:"btnBox"},[_c('van-button',{attrs:{"round":"","block":"","type":"info","native-type":"submit","size":"small"}},[_vm._v("修改密码")]),_c('div',{staticClass:"TipsBox"},[_c('router-link',{staticClass:"heSecret",attrs:{"tag":"span","to":"/login"}},[_vm._v("直接登陆")])],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }