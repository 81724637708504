import request from '@/utils/request';
// 密码登陆
export function Api_Login_YyPasswordLogin(params){
    return request('/Login/YyPasswordLogin',{
        method:'post',
        data: params
    })
}
//修改密码验证手机号
export function Api_Login_YyCheckCode(params){
    return request('/Login/YyCheckCode',{
        method:'post',
        data: params
    })
}
//修改密码
export function Api_Login_YyUpdatePassWord(params){
    return request('/Login/YyUpdatePassWord',{
        method:'post',
        data: params
    })
} 