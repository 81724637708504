import request from '@/utils/request';
// 实验室预约列表
export function Api_YyUserInfo_GetInfo(params){
    return request('/YyUserInfo/GetInfo',{
        method:'get',
        params: params
    })
}
// 修改用户信息
export function Api_YyUserInfo_AddEditInfo(params){
    return request('/YyUserInfo/AddEditInfo',{
        method:'post',
        data: params
    })
}
// 获取验证码
export function Api_System_SeedCode(params){
    return request('/System/SeedCodeTwo',{
        method:'get',
        params: params
    })
}
// 修改密码
export function Api_YyUserInfo_UpdatePassWord(params){
    return request('/YyUserInfo/UpdatePassWord',{
        method:'post',
        data: params
    })
}

